// door card styles
.doorcard {
  background-color: $grey400;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid $grey200;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: $grey300;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &-header {
    border-bottom: 1px solid $grey300;
    display: flex;
    align-items: center;
    padding: 20px 20px 10px 20px;
    padding-bottom: 10px;

    &-name {
      flex: 1;
      font-weight: bold;
      color: $black;
    }

    &-actions {
      display: flex;

      .door-button {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background: $grey200;
        }

        &.edit {
          color: $black;
        }
        &.delete {
          color: $red;
        }
      }
    }
  }

  &-body {
    padding: 20px 10px 10px 10px;

    .body-info {
      position: relative;
      margin-bottom: 20px;

      &-title {
        position: absolute;
        left: 10px;
        top: -6px;
        background: $white;
        padding: 0px 5px;
        font-weight: bold;
        color: $light_black;
        border-radius: 4px;
        font-size: 14px;
      }

      &-data {
        border: 1px solid $grey300;
        padding: 10px;
        padding-top: 20px;
        border-radius: 4px;
        background: $white;
        font-size: 14px;
        color: $black;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
