.mr-attachment-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  align-items: flex-start;

  .mr-attachment {
    color: $black;
    text-decoration: none;
    // margin-bottom: 10px !important;
    cursor: pointer;
    border: 1px solid $grey200;
    padding: 10px !important;
    border-radius: 4px;
    transition: color 0.3s;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &:hover {
      color: $primarycolor;
    }
  }
}
