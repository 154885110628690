.tabAuth {
  display: flex;
  list-style-type: none;
  align-self: flex-end;
  flex: 1;

  border-bottom: 1px solid $defaultborder;
  .tabAuth-list-item {
    cursor: pointer;
    div {
      padding: 12px;
      span.title {
        color: $defaulttextcolor;
        font-weight: bold;
        padding: 0px 10px;
        transition: color 0.2s;
      }
    }

    &.active {
      div {
        border-bottom: 3px solid $primarycolor;
        color: $black;
        span.title {
          font-family: $avertaSemiBold;
        }
      }
    }
  }
}
