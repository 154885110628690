// forget styles

.forget-password-container {
  margin-top: 80px;

  .forget-password {
    width: 40%;
    margin: 0px auto;
    padding: 50px 0px;

    @include responsive-for(md) {
      width: 80%;
      margin: 0px auto;
    }
    .forget {
      &-contents {
        padding: 20px 10px;
      }

      &-head {
        font-family: $avertaBold;
        font-size: $heading_one;
        color: $defaulttextcolor;
        margin-bottom: 20px;

        @include responsive-for(md) {
          font-size: $heading_two;
        }
      }

      &-fields {
        margin-bottom: 20px;

        &-title {
          font-family: $avertaSemiBold;
          font-size: $text;
          color: $defaulttextcolor;
          padding-bottom: 8px;
        }
      }

      &-action {
        width: 100%;
      }
    }
  }
}
