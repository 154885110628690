.custom-marker {
  background-color: $primarycolor;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
  transition: transform 0.2s;

  &::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    background-color: $white;
    border-radius: 50%;
  }

  &:hover {
    background-color: darken($primarycolor, 4%);
  }

  &.completed {
    background-color: #28a745;

    &:hover {
      background-color: darken(#28a745, 4%);
    }
  }
  &.ongoing {
    background-color: #ff4343;

    &:hover {
      background-color: darken(#ff4343, 4%);
    }
  }
  &.scheduled {
    background-color: #ffc107;

    &:hover {
      background-color: darken(#ffc107, 4%);
    }
  }
}

.floormap {
  &-title {
    font-weight: bold;
    padding-bottom: 20px;
  }

  &-popup {
    .popup {
      &-info {
        position: relative;
        margin-bottom: 20px;

        &-title {
          position: absolute;
          left: 4px;
          top: -6px;
          background: $white;
          padding: 0px 5px;
          font-weight: bold;
          color: $light_black;
          border-radius: 4px;
          font-size: 12px;
        }

        &-data {
          border: 1px solid $grey300;
          padding: 8px;
          padding-top: 12px;
          border-radius: 4px;
          background: $white;
          font-size: 12px;
          color: $black;
        }
      }
    }
  }
}

.unlinkdoorbtn {
  outline: none;
  border: none;
  min-width: 100px;
  padding: 8px 20px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: background 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: $red;
  color: $white;
  font-family: $avertaLight;
  width: 100%;
  font-weight: bold;

  &:hover {
    background: darken($red, 4%);
  }

  &:active {
    background: darken($red, 10%);
  }
}
