%jobtitle {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid $grey200;
  font-weight: bold;
  color: $grey100;
  margin-bottom: 10px;
}

%logs {
  background-color: $grey400;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 20px;
  border-left: 1px solid $grey200;
  height: 100%;

  .logs-title {
    @extend %jobtitle;
  }

  .logs-list {
    padding-top: 10px;

    &-item {
      position: relative;
      margin-bottom: 10px;
      border-bottom: 1px solid $grey300;

      .item-name {
        position: absolute;
        left: 0;
        top: 0px;
        font-size: 12px;
        color: $grey;
      }

      .item-time {
        position: absolute;
        right: 0;
        top: 0px;
        font-size: 12px;
        color: $grey;
      }

      .item-message {
        padding: 20px 0px;
      }

      &:last-child {
        margin-bottom: 0px;
        border-bottom: none;
      }
    }
  }
}

.jobdetail-header {
  padding: 10px 20px !important;
  border-bottom: 1px solid $grey200;
}

.jobdetail {
  &-attachments {
    background-color: $grey400;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0px 20px 20px 20px;
    border-right: 1px solid $grey200;
    height: 100%;

    .attachments-title {
      @extend %jobtitle;
    }

    .attachments-files {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 20px;

      .file {
        background: $white;
        padding: 10px;
        display: flex;
        align-items: center;
        border: 1px solid $grey200;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;

        &-icon {
          position: relative;
          top: 2px;
          margin-right: 4px;
          color: $black;
        }

        &-name {
          color: $black;
        }

        &:hover {
          background: $grey400;
        }
      }
    }

    .attachments-upload {
      cursor: pointer;
      padding-top: 20px;
      border: 1px dashed $grey200;
      border-radius: 4px;
      margin-top: 20px;
      padding-bottom: 30px;
      color: $grey100;
      transition: border 0.05s;

      .upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        border: 1px dashed $light_black;
      }
    }
  }

  &-editables {
    padding-top: 20px;

    .jobdetail {
      margin-bottom: 30px;

      &-title {
        @extend %jobtitle;
        padding-left: 20px;
        padding-right: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-description {
        padding: 0px 20px;
      }
    }

    .jobdescription {
      margin-bottom: 30px;

      &-title {
        @extend %jobtitle;
        padding-left: 20px;
        padding-right: 20px;
      }

      &-description {
        padding: 0px 20px;
      }
    }

    .signoff {
      &-title {
        @extend %jobtitle;
        padding-left: 20px;
        padding-right: 20px;
      }

      &-description {
        padding: 0px 20px;
      }
    }
  }

  &-logs {
    @extend %logs;

    &-loadmore {
      display: flex;
      justify-content: center;
      align-items: center;

      .loadmore-button {
        background: none;
        border: none;
        color: $primarycolor;
        font-weight: bold;
        cursor: pointer;
        padding-top: 20px;
      }
    }
  }
}

.file-container {
  .file {
    background: $white;
    padding: 10px;
    display: flex;
    align-items: center;
    border: 1px solid $grey200;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &-icon {
      position: relative;
      top: 2px;
      margin-right: 4px;
      color: $black;
    }

    &-name {
      color: $black;
    }

    &:hover {
      background: $grey400;
    }
  }
}
