.door-list-container {
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-column-gap: 10px;

  .door-list {
    &-cards {
      height: 700px;
      max-height: 800px;
      overflow-y: auto;
      padding-right: 10px;

      @include scrollbar(4px, $grey200, $grey300, $grey100);
    }

    &-loadmore {
      display: flex;
      justify-content: center;
      align-items: center;

      .loadmore-button {
        background: none;
        border: none;
        color: $primarycolor;
        font-weight: bold;
        cursor: pointer;
        padding-top: 20px;
      }
    }
  }

  .door-button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    border-radius: 4px;
    background: $grey300;

    &:hover {
      background: $grey200;
    }

    &.add {
      margin-right: 10px;
      color: $black;
    }
  }
}

.floor-map {
  overflow-x: auto;

  &-message {
    padding: 20px;

    &.error {
      color: $red;
    }

    &.processing {
    }
  }
}
