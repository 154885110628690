// verify styles
.auth-verification-container {
  margin-top: 80px;

  .auth-verification {
    width: $default_width;
    margin: 0px auto;
    padding: 50px 0px;

    &-header {
      text-align: center;
      color: $black;
    }

    &-info {
      text-align: center;
      color: $lighttextcolor;
    }

    &-suggestion {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid $grey400;

      .suggestion-para {
        text-align: center;
      }
      
      .verify-button {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}