// CARD HOC STYLES

.card-container {
  width: 100%;
  background-color: $white;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid $defaultborder;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
}
