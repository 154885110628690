// TAB STYLES
.tab {
  &-title {
    display: flex;
    border-bottom: 1px solid $defaultborder;

    .tab-item {
      padding: 20px 0px 10px 0px;
      margin-right: 40px;
      font-family: $avertaSemiBold;
      cursor: pointer;
      color: $light_black;

      &:first-child {
        margin-left: 30px;
      }

      &:last-child {
        margin-right: 30px;
      }

      &.active {
        border-bottom: 3px solid $primarycolor;
        color: $black;
      }
    }
  }

  //   &-content {}
}
