// ALL THE GENERAL STYLES WILL BE HERE

//text editor
.rdw-editor-main {
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  height: 50vh;
  padding: 0px 5px;
}

.rdw-editor-wrapper {
  width: 75vw;
  @include responsive-for(xlg) {
    width: 67vw;
  }

  @include responsive-for(lg) {
    width: 87vw;
  }
}
// @override SLICK DOTS
.slick-dots {
  li {
    button:before {
      color: $grey;
      font-size: 10px;
    }

    &.slick-active {
      button:before {
        color: $teal;
      }
    }
  }
}

body {
  background: #fafafa;
}
// NO RESULTS
.no-results {
  color: $light_black;
}

// BACKGROUND STYLES
.bg {
  // COLORS
  &-teal {
    background-color: $teal;
  }
  &-orange {
    background-color: $orange;
  }
  &-green {
    background-color: $primarycolor;
  }
  &-purple {
    background-color: $purple;
  }
  &-light-teal {
    background-color: $light_teal;
  }
  &-white {
    background-color: $white;
  }
  &-black {
    background-color: $black;
  }
  &-red {
    background-color: $red;
  }
  &-blue {
    background-color: $blue;
  }
  &-light-black {
    background-color: $light_black;
  }
  &-grey {
    background-color: $grey;
  }
  &-light-grey {
    background-color: $light_grey;
  }
}

// TEXT STYLES
.text {
  // ALIGNMENTS
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }
  &-center {
    text-align: center;
  }

  // HEADINGS
  &-xlg {
    font-size: $extralarge;
    color: $black;
  }
  &-lg {
    font-size: $large;
    color: $black;
  }
  &-md {
    font-size: $medium;
    color: $black;
  }
  &-sm {
    font-size: $small;
    color: $black;
  }
  &-xsm {
    font-size: $extrasmall;
    color: $black;
  }
  &-thin {
    font-size: $thin;
    color: $black;
  }
  &-para {
    font-size: $thin;
    color: $black;
  }
  &-xthin {
    font-size: $extrathin;
    color: $black;
  }
  &-hairline {
    font-size: $hairline;
    color: $black;
  }

  // COLORS
  &-teal {
    color: $teal;
  }
  &-orange {
    color: $orange;
  }
  &-green {
    color: $primarycolor;
  }
  &-purple {
    color: $purple;
  }
  &-light-teal {
    color: $light_teal;
  }
  &-white {
    color: $white;
  }
  &-black {
    color: $black;
  }
  &-red {
    color: $red;
  }
  &-blue {
    color: $blue;
  }
  &-light-black {
    color: $light_black;
  }
  &-grey {
    color: $grey;
  }
  &-light-grey {
    color: $light_grey;
  }

  // TRANSFORMS
  &-upper {
    text-transform: uppercase;
  }
  &-lower {
    text-transform: lowercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-bold {
    font-family: $avertaBold;
  }
  &-italic {
    font-style: italic;
  }
  &-underline {
    text-decoration: underline;
  }
}

// MARGINS
.m {
  // MARGIN-LEFT
  &l-1 {
    margin-left: 2px;
  }
  &l-2 {
    margin-left: 4px;
  }
  &l-3 {
    margin-left: 8px;
  }
  &l-4 {
    margin-left: 10px;
  }
  &l-5 {
    margin-left: 20px;
  }

  // MARGIN-RIGHT
  &r-1 {
    margin-right: 2px;
  }
  &r-2 {
    margin-right: 4px;
  }
  &r-3 {
    margin-right: 8px;
  }
  &r-4 {
    margin-right: 10px;
  }
  &r-5 {
    margin-right: 20px;
  }

  // MARGIN-TOP
  &t-1 {
    margin-top: 2px;
  }
  &t-2 {
    margin-top: 4px;
  }
  &t-3 {
    margin-top: 8px;
  }
  &t-4 {
    margin-top: 10px;
  }
  &t-5 {
    margin-top: 20px;
  }

  // MARGIN-BOTTOM
  &b-1 {
    margin-bottom: 2px;
  }
  &b-2 {
    margin-bottom: 4px;
  }
  &b-3 {
    margin-bottom: 8px;
  }
  &b-4 {
    margin-bottom: 10px;
  }
  &b-5 {
    margin-bottom: 20px;
  }
}

// PADDINGS
.p {
  // PADDING-LEFT
  &l-1 {
    padding-left: 2px;
  }
  &l-2 {
    padding-left: 4px;
  }
  &l-3 {
    padding-left: 8px;
  }
  &l-4 {
    padding-left: 10px;
  }
  &l-5 {
    padding-left: 20px;
  }

  // PADDING-RIGHT
  &r-1 {
    padding-right: 2px;
  }
  &r-2 {
    padding-right: 4px;
  }
  &r-3 {
    padding-right: 8px;
  }
  &r-4 {
    padding-right: 10px;
  }
  &r-5 {
    padding-right: 20px;
  }

  // PADDING-TOP
  &t-1 {
    padding-top: 2px;
  }
  &t-2 {
    padding-top: 4px;
  }
  &t-3 {
    padding-top: 8px;
  }
  &t-4 {
    padding-top: 10px;
  }
  &t-5 {
    padding-top: 20px;
  }

  // PADDING-BOTTOM
  &b-1 {
    padding-bottom: 2px;
  }
  &b-2 {
    padding-bottom: 4px;
  }
  &b-3 {
    padding-bottom: 8px;
  }
  &b-4 {
    padding-bottom: 10px;
  }
  &b-5 {
    padding-bottom: 20px;
  }
}
