.button {
  outline: none;
  border: none;
  min-width: 100px;
  padding: 8px 20px;
  border-radius: 4px;
  font-family: $avertaBold;
  font-size: $text;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: background 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &.primary {
    background: $primarycolor;
    color: $white;
    font-family: $avertaSemiBold;
    &:hover {
      background: darken($primarycolor, 5%);
    }

    &:active {
      background: darken($primarycolor, 10%);
    }
  }

  &.default {
    background: $black;
    color: $white;
    font-family: $avertaSemiBold;
    &:hover {
      background: lighten($black, 5%);
    }

    &:active {
      background: lighten($black, 10%);
    }
  }

  &.ghost {
    background: $white;
    color: $black;
    font-family: $avertaSemiBold;
    border: 1px solid $grey200;

    &:hover {
      background: darken($white, 5%);
    }

    &:active {
      background: darken($white, 10%);
    }
  }

  &:disabled {
    background-color: $grey;
    cursor: not-allowed;
    &:hover {
      background-color: $grey;
    }

    &:active {
      background-color: $grey;
    }
  }

  &.icon-button {
    outline: none;
    border: none;
    // width: 100%;
    min-width: 0px;
    font-size: $thin;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
    color: $black;

    &:hover {
      color: $defaulttextcolor;
    }
  }

  &.fit-content {
    width: 100%;
  }
}
