//GENERICS LINK
.a {
  text-decoration: none;
  display: inline-block;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
