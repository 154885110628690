.doordetail-container {
  @extend %template-input;

  .parts-content {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: $primarycolor;
    }
  }
}

.doordetail {
  &-header {
    padding: 20px 20px 10px 20px !important;
    border-bottom: 1px solid $grey200;
  }

  &-logs {
    @extend %logs;
  }
}
