// HEADER STYLE// HEADER STYLE
.header-container {
  padding-top: 30px;
  padding-bottom: 30px;

  @include responsive-for(lg) {
    padding-top: 30px;
    margin-bottom: 30px;
  }

  @include responsive-for(md) {
    padding-top: 20px;
    margin-bottom: 30px;
  }

  .header-trial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 0px;
    font-size: $hairline;
    background-color: red;
    color: white;
    text-align: center;
    letter-spacing: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: $medium;

      @include responsive-for(lg) {
        font-size: $small;
      }

      @include responsive-for(sm) {
        font-size: $extrasmall;
      }

      .header-menu {
        font-size: 30px;
        margin-right: 20px;
        cursor: pointer;
        transition: color 0.2s;
        display: block;
        position: relative;
        top: 2px;
        color: $black;
        background-color: $white;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        border: 1px solid $grey200;

        @include responsive-for(lg) {
          display: block;
        }

        &:hover {
          color: $nt_darkdefault;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;

      .header-buttons {
        padding-right: 10px;
        display: flex;
        align-items: center;

        &-add {
          background: none;
          border: none;
          outline: none;
          font-size: 20px;
          color: $grey100;
          width: 40px;
          height: 40px;
          margin: 0px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
          transition: background 0.2s, color 0.2s;

          &:hover {
            background: $grey;
            color: $white;
          }
        }
      }

      .logged-user {
        cursor: pointer;
        padding-left: 20px;
        // border-left: 2px solid $grey200;

        &-container {
          display: flex;
          align-items: center;

          .logged-user {
            &-icon {
              background: $white;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $black;
              cursor: pointer;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
              transition: background 0.2s;
              border: 1px solid $grey200;
            }

            &-name {
              font-family: $avertaSemiBold;
              padding: 0px 10px;
              color: $black;
            }

            &-arrow-down {
              color: $black;
              position: relative;
              top: 4px;
            }
          }
        }

        .menuItem {
          font-size: 16px !important;
          font-family: $avertaSemiBold;

          &.danger {
            color: $red;
          }
        }
      }
    }
  }
}
