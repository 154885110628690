// COLORS
$teal: #3180a9;
$orange: #ff8144;
$yellow: #ffc107;
$green: #28a745;
$purple: #a259f4;
$light_teal: #11a7af;
$white: #ffffff;
$black: #353535;
$red: #ff4343;
$blue: #2480f5;
$light_black: #a1a1a1;
// $grey: #e1e1e1;
$light_grey: #f8f8f8;

$grey: #989898;
$grey100: #a1a1a1;
$grey200: #e1e1e1;
$grey300: #f1f1f1;
$grey400: #f8f8f8;
$grey450: #fcfcfc;
$grey500: #ffffff;

$primarycolor: #0284a4;
$nt_darkdefault: #005e75;

// TEXT COLORS
$defaulttextcolor: #555555;
$lighttextcolor: #707070;
$secondarytextcolor: #989898;

// BORDER COLORS
$defaultborder: #e1e1e1;

// FONT FAMILIES
$avertaLight: AvertaLight;
$avertaSemiBold: AvertaSemiBold;
$avertaBold: AvertaBold;
$avertaExtraBold: AvertaExtraBold;

$large_heading: 32px;
$heading_one: 24px; // Bold
$heading_two: 20px; // Bold
$text: 16px; // Medium for Links/Text/SubHeaing, Regular
// FONT SIZES
// DEFAULT => 16px
// 12px => 0.75rem
// 20px => 1.25rem
// 24px => 1.5rem
// 28px => 1.75rem
// 32px => 2rem
// 38px => 2.375rem
// 80px => 5rem
$extralarge: 3.5rem;
$large: 2.375rem;
$medium: 2rem;
$small: 1.75rem;
$extrasmall: 1.5rem;
$thin: 1.25rem;
$extrathin: 1rem;
$hairline: 0.75rem;

// WIDTH
$default_width: 85%;
