.googleplaces {
  position: relative;
  width: 100%;

  &-input {
    position: relative;
    flex: 2;
    display: flex;
    align-items: center;
    width: 100%;

    &.location {
      flex: 1;
    }

    .icon-container {
      display: flex;
      align-items: center;
      color: $green;
    }

    input {
      height: 30px;
      width: 100%;
      border: none;
      padding: 10px;
      font-size: $text;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  &-dropdown {
    z-index: 100;
    width: 100%;
    position: absolute;
    top: 110%;
    background-color: $white;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    max-height: 150px;
    overflow-y: auto;

    &-items {
      padding: 8px 10px;
      border-bottom: 1px solid $grey200;

      &:hover {
        background-color: $green;
        color: $white;
        cursor: pointer;
      }

      &.active {
        background-color: $green;
        color: $white;
        cursor: pointer;
      }
    }
  }
}
