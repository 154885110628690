.inputfield {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $defaultborder;
  padding: 10px 12px;
  font-size: $extrathin;
  background-color: $grey400;
  font-family: $avertaSemiBold;
  color: $defaulttextcolor;
  transition: border 0.2s, background 0.2s;
  resize: none;

  &.borderless {
    border: none;
    outline: none;
    background-color: #fafafa;

    &:focus {
      outline-offset: 0px;
      background-color: #fafafa;
      outline: none;
      border: none;
    }

    &:hover {
      border: none;
    }
  }

  &::placeholder {
    font-family: $avertaSemiBold;
    color: $grey;
  }

  &:hover {
    background-color: $grey400;
    border: 1px solid $primarycolor;
  }

  &:focus {
    background-color: $white;
    outline-offset: 0px;
    outline: none;
    border: 1px solid $primarycolor;
  }

  &:disabled {
    &:hover {
      background-color: $grey400;
      border: 1px solid $defaultborder;
    }
  }

  &.error {
    border: 1px solid $red;

    &:focus {
      border: 1px solid $red;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.disabled {
  &:hover {
    background-color: $grey400;
    border: 1px solid $defaultborder;
  }
}

.searchbox {
  position: relative;

  .searchfield {
    position: relative;
    width: 100%;
    padding: 10px 25px 10px 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid $defaultborder;
    background-color: #fafafa;
    font-size: $extrathin;
    font-family: $avertaSemiBold;
    color: $defaulttextcolor;
    transition: border 0.2s, background 0.2s;
    resize: none;

    &::placeholder {
      font-family: $avertaSemiBold;
      color: $grey;
    }

    &.error {
      border: 1px solid $red;

      &:focus {
        border: 1px solid $red;
      }
    }
  }

  .icon {
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-40%);
    color: $grey200;
  }
}
