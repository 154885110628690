.dashboard-card-title {
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
}

.dashboard-job-status {
  .icon {
    height: 20px;
    width: 8px;
    border-radius: 10px;

    &.red {
      background-color: $red;
    }
    &.yellow {
      background-color: $yellow;
    }

    &.green {
      background-color: $green;
    }
    &.orange {
      background-color: $orange;
    }
  }

  .lighttext {
    color: $secondarytextcolor;
  }
}

.dashboard-job-numbers {
  .lighttext {
    color: $secondarytextcolor;
  }
}

.quicklinks {
  background-color: $primarycolor;
  color: $white;
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
