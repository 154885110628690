// add template styles
%template-input {
  input,
  select {
    width: 100%;
    outline: none;
    border-radius: 4px;
    border: 1px solid $defaultborder;
    padding: 10px 12px;
    font-size: $extrathin;
    background-color: $grey400;
    color: $defaulttextcolor;

    &:focus {
      border: 1px solid $grey100;
    }

    &::placeholder {
      color: $grey;
    }
  }

  button {
    outline: none;
    border: none;
    background: none;
    padding: 4px;
    cursor: pointer;
    color: $black;

    display: flex;
    justify-content: center;
    align-items: center;

    &.close {
      color: $red;
      font-size: 20px;
    }
  }
}
.add-template-container {
  .template-title {
    font-family: $avertaSemiBold;
  }

  .parts-content {
    position: relative;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: $primarycolor;
    }
  }

  @extend %template-input;
}
