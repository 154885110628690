//View Client
.add-contractor {
}

.client-wrapper {
  .avatar {
    // height: 150px;
    width: 150px;
  }
}
